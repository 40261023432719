import { CSSProperties } from 'react';

export const cssStyleAppLink = (skew: number[]) => {
  const x = skew[0] * 4;
  const y = skew[1] * 6;
  const max = Math.max(Math.abs(skew[0]), Math.abs(skew[1]));
  const blur = 0.4 + 6 * max;
  const colour = `rgba(111, 86, 67, ${0.4 - 0.6 * max}`;
  return {
    transform: `rotateX(${skew[1] * 8}deg) rotateY(${-skew[0] * 8}deg)`,
    boxShadow: `${x}rem ${y}rem ${blur}rem ${colour}`,
  };
};

export const cssStyleContent = (skew: number[]): CSSProperties => {
  const x = skew[0] * 2;
  const y = skew[1] * 3;
  const max = Math.max(Math.abs(skew[0]), Math.abs(skew[1]));
  const blur = 0.4 + 3 * max;
  const colour = `rgba(111, 86, 67, ${0.6 - 0.6 * max}`;
  return { boxShadow: `inset ${x}rem ${y}rem ${blur}rem ${colour}` };
};

export const cssStyleIndicator = (skew: number[]): CSSProperties => {
  const top = `${0 + skew[1] * 100}%`;
  const left = `${0 + skew[0] * 100}%`;
  return { top, left };
};
