import React, { useState } from 'react';
import AppLink from '../components/AppLink';
import { CardsList } from '../components/Content';

function Content() {
  const CSSBlock = 'mobile-content';
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const apps = [
    {
      url: 'https://emdee.io',
      description:
        'A Markdown editor available on all platforms, with a clean and intuitive user experience.',
      text: (
        <span>
          e<b>md</b>ee.io
        </span>
      ),
      card: CardsList.EMDEE,
    },
    {
      url: 'https://whattheport.com',
      description:
        'Having trouble understanding the different versions of USB? Hopefully this can help.',
      text: <span>What The Port</span>,
      card: CardsList.WHAT_THE_PORT,
    },
    // {
    //   url: 'https://emdee.io',
    //   description:
    //     'A Markdown editor available on all platforms, with a clean and intuitive user experience.',
    //   text: <span>three</span>,
    // },
    // {
    //   url: 'https://whattheport.com',
    //   description:
    //     'Having trouble understanding the different versions of USB? Hopefully this can help.',
    //   text: <span>four</span>,
    // },
  ];

  return (
    <div className={`${CSSBlock}`}>
      {apps.map((app, i) => {
        const dir = i - activeIndex;
        const translate =
          dir < 0
            ? `translate3d(calc(-80% + ${dir * 1}rem), 0, 0)`
            : dir > 0
            ? `translate3d(calc(80% + ${dir * 1}rem), 0, 0)`
            : '';
        const rotate =
          dir < 0 ? `rotateY(90deg)` : dir > 0 ? `rotateY(-90deg)` : '';
        const scale = dir !== 0 ? `scale3d(0.7, 0.7, 0.7)` : '';
        const style = {
          transform: `${translate} ${rotate} ${scale}`,
          zIndex: dir < 0 ? i : dir > 0 ? -i : 0,
        };
        return (
          <AppLink
            key={i}
            classModifier={
              i === activeIndex ? 'focused' : i < activeIndex ? 'left' : 'right'
            }
            url={app.url}
            description={app.description}
            text={app.text}
            style={style}
            card={app.card}
          />
        );
      })}
      <div className={`${CSSBlock}__controls`}>
        <button
          className={`${CSSBlock}__control previous`}
          onClick={() =>
            setActiveIndex(activeIndex > 0 ? activeIndex - 1 : apps.length - 1)
          }>
          {'<'}
        </button>
        <button
          className={`${CSSBlock}__control next`}
          onClick={() =>
            setActiveIndex(activeIndex < apps.length - 1 ? activeIndex + 1 : 0)
          }>
          {'>'}
        </button>
      </div>

      {/* <AppLink
          url="https://whattheport.com"
          description="Having trouble understanding the different versions of USB? Hopefully this can help."
          text={<span>Windows 11 View</span>}
        />
        <AppLink
          url="https://whattheport.com"
          description="Having trouble understanding the different versions of USB? Hopefully this can help."
          text={<span>Car Simulator</span>}
        /> */}
    </div>
  );
}

export default Content;
