import React, { useState } from 'react';
import AppLink from './AppLink';

export enum CardsList {
  'EMDEE' = 'emdee',
  'WHAT_THE_PORT' = 'what-the-port',
}

function Content() {
  const CSSBlock = 'main-content';
  const [activeSection, setActiveSection] = useState<number>(0);

  return (
    <div className={`${CSSBlock}`}>
      <AppLink
        url="https://emdee.io"
        description="A Markdown editor available on all platforms, with a clean and intuitive user experience."
        text={
          <span>
            e<b>md</b>ee.io
          </span>
        }
        card={CardsList.EMDEE}
      />
      <AppLink
        url="https://whattheport.com"
        description="Having trouble understanding the different versions of USB? Hopefully this can help."
        text={<span>What The Port</span>}
        card={CardsList.WHAT_THE_PORT}
      />
      {/* <AppLink
      url="https://whattheport.com"
      description="Having trouble understanding the different versions of USB? Hopefully this can help."
      text={<span>Windows 11 View</span>}
    />
    <AppLink
      url="https://whattheport.com"
      description="Having trouble understanding the different versions of USB? Hopefully this can help."
      text={<span>Car Simulator</span>}
    /> */}
    </div>
  );
}

export default Content;
