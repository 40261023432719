import * as THREE from 'three';

import Camera from './Core/camera';
import Renderer from './Core/renderer';
import Resources from './Core/resources';
import Lighting from './Core/lighting';

let ThreeJSViews = {};

const easeInOutSine = x => -(Math.cos(Math.PI * x) - 1) / 2;

export default class Simulator {
  _sizes = {
    width: 250,
    height: 250,
  };
  _scene = null;
  _canvas = null;
  _autoRotate = true;

  constructor(canvas, resource) {
    ThreeJSViews[resource] = this;

    this._canvas = canvas;
    this._scene = new THREE.Scene();
    this._lighting = new Lighting(this);
    this._camera = new Camera(this);
    this._renderer = new Renderer(this);
    this._resources = new Resources({
      simulation: this,
      resource,
      onLoad: model => this.scene.add(model),
    });

    this._tick();
  }

  get canvas() {
    return this._canvas;
  }
  get camera() {
    return this._camera._camera;
  }
  get scene() {
    return this._scene;
  }
  get sizes() {
    return this._sizes;
  }
  get model() {
    return this._resources._model;
  }

  oldElapsedTime = 0;
  clock = new THREE.Clock();

  _tick() {
    const elapsedTime = this.clock.getElapsedTime();
    this._camera.onTick();
    this._renderer.onTick();
    this._lighting.onTick();

    if (this._autoRotate && this.model)
      this.model.rotation.y = 0.5 - easeInOutSine(elapsedTime * 0.6);

    window.requestAnimationFrame(this._tick.bind(this));
  }

  onUpdateOffset(x, y) {
    if (this.camera) {
      this.camera.position.x = -5 * x;
      this.camera.position.y = 1 + -5 * y;
    }
  }
}
