import React, { CSSProperties, useRef, useState } from 'react';
import {
  cssStyleAppLink,
  cssStyleContent,
  cssStyleIndicator,
} from './appLinkHelper';
import CardContentEmdee from './CardContentEmdee';
import CardContentWTP from './CardContentWTP';
import { CardsList } from './Content';

const easeInOutSine = (x: number): number => -(Math.cos(Math.PI * x) - 1) / 2;
const duration = 2000;

const ease = (
  originX: number,
  originY: number,
  onUpdate: (x: number, y: number) => void,
  timeElapsed = 0
) => {
  timeElapsed += 100;
  const easeVal = 1 - easeInOutSine(timeElapsed / duration);
  originX = originX * easeVal;
  originY = originY * easeVal;
  onUpdate(originX, originY);

  if (timeElapsed < duration)
    requestAnimationFrame(() => ease(originX, originY, onUpdate, timeElapsed));
};

interface AppLinkProps {
  text: JSX.Element;
  description: string;
  url: string;
  style?: CSSProperties;
  classModifier?: string;
  card: CardsList;
}

export interface CardContentRef {
  onMove: (x: number, y: number) => void;
  onLeave: (x: number, y: number) => void;
}

const AppLink: React.FC<AppLinkProps> = ({
  text,
  description,
  url,
  style,
  classModifier,
  card,
}) => {
  const CSSBlock = 'applink';
  const ref = useRef(null);
  const contentRef = useRef<CardContentRef>(null);
  const [skew, setSkew] = useState<[number, number]>([0, 0]);
  const [modifierClass, setModifierClass] = useState('');

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    var { x, y, width, height } = (
      e.target as HTMLDivElement
    ).getBoundingClientRect();
    const xOffset = e.clientX - x;
    const yOffset = e.clientY - y;
    const normalised = [(width - xOffset) / width, (height - yOffset) / height];
    const values = [0.5 - normalised[0], 0.5 - normalised[1]];
    setSkew([values[0], values[1]]);
    contentRef.current?.onMove(values[0], values[1]);
  };

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setModifierClass('hover');
  };

  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setModifierClass('');
    setSkew([0, 0]);
    if (contentRef.current) ease(skew[0], skew[1], contentRef.current.onLeave);
  };

  return (
    <div
      className={`${CSSBlock}__wrapper ${
        classModifier ? `${CSSBlock}--${classModifier}` : ''
      }`}
      style={style || {}}>
      <div
        className={`${CSSBlock} ${CSSBlock}--${modifierClass}`}
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        style={cssStyleAppLink(skew)}
        onClick={() => window.open(url, '_blank')}>
        <div className={`${CSSBlock}__title`}>{text}</div>
        <div className={`${CSSBlock}__description`}>{description}</div>
        <div className={`${CSSBlock}__content`} style={cssStyleContent(skew)}>
          {card === CardsList.EMDEE && <CardContentEmdee ref={contentRef} />}
          {card === CardsList.WHAT_THE_PORT && (
            <CardContentWTP ref={contentRef} />
          )}
        </div>
        <span
          className={`${CSSBlock}__indicator`}
          style={cssStyleIndicator(skew)}></span>
      </div>
    </div>
  );
};

export default AppLink;
