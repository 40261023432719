import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

export default class Camera {
  _camera;

  constructor(simulation) {
    this._simulation = simulation;
    this._initSceneCamera();
  }

  _initSceneCamera() {
    this._camera = new THREE.PerspectiveCamera(
      20,
      this._simulation._sizes.width / this._simulation._sizes.height,
      0.1,
      1000
    );
    this._camera.position.set(0, 1, -10);
    this._simulation.scene.add(this._camera);

    this._controls = new OrbitControls(this._camera, this._simulation.canvas);
    this._controls.enableDamping = true;
    this._controls.enablePan = false;
    this._controls.enableZoom = false;
  }

  onTick() {
    this._controls.update();
  }
}
