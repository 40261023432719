import React, { useImperativeHandle, useState } from 'react';
import { CardContentRef } from './AppLink';

const CardContentEmdee = React.forwardRef<CardContentRef>((_, ref) => {
  const CSSBlock = 'card-content-emdee';
  const [skewValue, setSkewValue] = useState([0, 0]);

  useImperativeHandle(ref, () => ({
    onMove(x: number, y: number) {
      setSkewValue([x, y]);
    },

    onLeave(x: number, y: number) {
      setSkewValue([x, y]);
    },
  }));

  const elements = [
    { weight: 400, char: 'e' },
    { weight: 700, char: 'm' },
    { weight: 700, char: 'd' },
    { weight: 400, char: 'e' },
    { weight: 400, char: 'e' },
    { weight: 400, char: '.' },
    { weight: 400, char: 'i' },
    { weight: 400, char: 'o' },
  ];

  return (
    <div className={CSSBlock}>
      <div className={`${CSSBlock}__background`}></div>
      <div className={`${CSSBlock}__title`}>
        {elements.map((el, i) => (
          <span
            key={i}
            className={`${CSSBlock}__title-text`}
            style={{
              fontWeight: el.weight,
              animationDelay: `${0.1 + 0.2 * i}s`,
              textShadow: `${skewValue[0] * 20}px ${
                skewValue[1] * 20
              }px 3px rgba(0, 0, 0, ${
                Math.max(Math.abs(skewValue[0]), Math.abs(skewValue[1])) * 60
              }%)`,
            }}>
            {el.char}
          </span>
        ))}
        <span
          className={`${CSSBlock}__title-underline`}
          style={{
            boxShadow: `${skewValue[0] * 20}px ${
              skewValue[1] * 20
            }px 3px rgba(0, 0, 0, ${
              Math.max(Math.abs(skewValue[0]), Math.abs(skewValue[1])) * 60
            }%)`,
          }}></span>
      </div>
      <div className={`${CSSBlock}__loading-indicator`}></div>
    </div>
  );
});

export default CardContentEmdee;
