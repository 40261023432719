import React, { useEffect, useState } from 'react';
import Logo from '../assets/logo-large-retro.svg';
import MobileContent from '../components-mobile/Content';
import MainContent from '../components/Content';

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

const Root: React.FC = () => {
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    setIsMobile(isMobileDevice());
  }, []);

  useEffect(() => {
    document.body.classList.toggle('isMobile', isMobile);
  }, [isMobile]);

  return (
    <>
      {isMobile ? <MobileContent /> : <MainContent />}

      <div className="logo">
        <img src={Logo} />
        <div className="keywords">
          Tech Concepts<span>|</span>Consultancy<span>|</span>UI / ux Design
        </div>
      </div>
    </>
  );
};

export default Root;
